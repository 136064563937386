<template>
  <div style="width: 100%;">
    <v-overlay v-if="reloading" z-index=10 class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <div class="pt-15">Reiniciando...</div>
    </v-overlay>
    <v-container v-else>
      <v-snackbar
        v-model="calling"
        timeout= -1
        color="success"
        bottom
      >
        Llamando a {{calling_to}} ...
      </v-snackbar>
      <div v-if="time>0" :class="(time>15)?'black--text text-right':'red--text blink text-right'">
        <v-icon :color="(time>15)?'black':'red'">mdi-clock-time-nine-outline</v-icon>
        {{timeFormatted}}
      </div>

      <div class="text-center" style="position: relative; height: 85vh;">
        <v-alert v-if="allowing && !cameraError" 
          text outlined prominent type="info"
          color="red"
        >
          <h3 class="text-h5">
            Por favor, cuando le sea requerido, permita el uso de la cámara y el micrófono.
          </h3>
        </v-alert>
        <v-alert
          text color="red lighten-1" outlined prominent v-if="cameraError" type="error"
        >
          <h3 class="text-h5">
            Uso de la cámara y el micrófono.
          </h3>
          <div class="pt-4">
            Fue denegado el uso de la cámara y el micrófono.<br>
            Para poder realizar la llamada es necesario permitir el acceso a los mismos.
          </div>
          <v-divider
            class="my-4 red"
          ></v-divider>
          <v-btn
            color="primary" outlined elevation="2" large @click="getUserMedia"
          >
            <v-icon dark class="pr-4">
              mdi-crosshairs-gps
            </v-icon>
            Reintentar
          </v-btn>
        </v-alert>
        <div v-if="!cameraError" :class="(showRemote ? 'localStream pip': 'localStream')">
          <video playsinline id="localStream" muted></video>
        </div>
        <div v-if="!cameraError" class="remoteStream" :style="'display:' + (showRemote ? 'block' : 'none')">
          <video playsinline id="remoteStream" class="crop"></video>
        </div>
        <div class="hangup">
          <v-btn @click="hangup()" dark large color="red" fab id="hangup">
            <v-icon>mdi-phone-hangup</v-icon>
          </v-btn>
        </div>
        <canvas v-if="showCanvas" id="photo" width="320" height="320"></canvas>
        <canvas v-if="showCanvas" id="photoPush" width="50" height="50"></canvas>
        <audio v-if="ringing" autoplay loop>
          <source src="sound/calling_ringtone.mp3" type="audio/mpeg">
        </audio>
      </div>
    </v-container>
  </div>
</template>

<script>
import ConnectyCube from "connectycube";
import { mapGetters } from "vuex";
import store from "../store";
import Vue from 'vue';

export default {
  props: ["QRId", "building", "call_time", "doors", "floor", "apartment", "contacts", "cc_data", "calling_to"],

  data: () => ({
    showCanvas: true,
    showRemote: false,
    calling: false,
    allowing: true,
    oponentConnection: null,
    session: null,
    time: 0,
    timeFormatted: '',
    userAnswer: null,
    ringing: false,
    reloading: false,
    cameraError: false,
    hangupCalled: false,
    //audioTrack: null,
  }),

  watch: {
    time: function (seconds) {
      this.timeFormatted = new Date(seconds * 1000).toISOString().substr(14, 5);
    }
  },

  created() {
    var self = this;
    setTimeout(() => {

      if (ConnectyCube.chat) {
        if (ConnectyCube.chat.isConnected) {
            self.createSessionAndCall();
            return;
        }
    }

      const CREDENTIALS = {
        appId: self.cc_data.app_id,
        token: self.cc_data.token,
      };
      ConnectyCube.init(CREDENTIALS);
//    ConnectyCube.videochat.onCallListener = this.onCallListener.bind(this);

      const token = ConnectyCube.service.sdkInstance.session.token;

      const userCredentials = {
        userId: self.cc_data.user_id,
        password: token
      };
      ConnectyCube.chat
        .connect(userCredentials)
        .then(() => {
            self.createSessionAndCall();
        })
      .catch((error) => {
        console.log("session3",error);
      });
    }, 0);
  },

  computed: {
    ...mapGetters({
      call: "call",
      whiteLabel: "whiteLabel",
    }),
  },

  methods: {
    createSessionAndCall() {
        ConnectyCube.videochat.onAcceptCallListener = this.onAcceptCallListener;
        ConnectyCube.videochat.onRejectCallListener = this.onRejectCallListener;
        ConnectyCube.videochat.onStopCallListener = this.onStopCallListener;
        ConnectyCube.videochat.onUserNotAnswerListener = this.onUserNotAnswerListener;
        ConnectyCube.videochat.onRemoteStreamListener = this.onRemoteStreamListener;
        ConnectyCube.videochat.onSessionCloseListener = this.onSessionCloseListener;

      const calleesIds = Object.keys(this.contacts);
      const sessionType = ConnectyCube.videochat.CallType.VIDEO;
      const additionalOptions = {};
      const session = ConnectyCube.videochat.createNewSession(calleesIds, sessionType, additionalOptions);
      session.onDataChannelMessageListener = this.onDataChannelMessageListener;
      session.onDataChannelOpenListener = this.onDataChannelOpenListener;
      session.onDataChannelCloseListener = this.onDataChannelCloseListener;
      this.session = session;

      this.getUserMedia();
    },

    captureImg(repeat) {
        var self = this;
        const calleesIds = Object.keys(this.contacts);
        const canvas = document.getElementById("photo");
        const context = canvas.getContext("2d");
        var vw = document.getElementById("localStream").videoWidth;
        var vh = document.getElementById("localStream").videoHeight;
        var posx = 0;
        var posy = 0;
        var swidth = vw;
        var sheight = vh;
        if (vh>vw) {
          sheight = swidth;
          posy = (vh-vw) / 2;
        }
        else {
          swidth = sheight;
          posx = (vw-vh) / 2;
        }
        context.drawImage(document.getElementById("localStream"), posx, posy, swidth, sheight, 0, 0, canvas.width, canvas.height);

        var dataimage = context.getImageData(0, 0, canvas.width, canvas.height);
        var i = -4;
        var blockSize = 5;
        var nonzero = false;
        while ( (i += blockSize * 4) < dataimage.data.length ) {
            if (dataimage.data[i]!=0 || dataimage.data[i+1]!=0 || dataimage.data[i+2]!=0) {
                nonzero = true;
                break;
            }
        }
        if (!nonzero) {
            if (repeat>0) {
              setTimeout(() => {
                self.captureImg(repeat-1);
              }, 1000);
              return;
            }
        }

        var imgdata = canvas.toDataURL("image/jpeg",0.85).substring(23);
        const canvasPush = document.getElementById("photoPush");
        const contextPush = canvasPush.getContext("2d");
        contextPush.drawImage(document.getElementById("localStream"), posx, posy, swidth, sheight, 0, 0, canvasPush.width, canvasPush.height);
        var imgdataPush = canvasPush.toDataURL("image/jpeg",0.5).substring(23);

        this.showCanvas = false;
        store.dispatch("saveCall", {QRId:self.QRId,floorId:self.floor,apartmentId:self.apartment,call:{img:imgdata}}).then(function(saved) {

          self.calling = true;

          self.sendPush(calleesIds.map(function(item) { return parseInt(item, 10); }),self.session.ID,imgdataPush);

          self.$emit('ring',true);
          //self.ringing = true;
          const extension = {};
          self.session.call(extension, (error) => {
             console.log("session",error);
          });
        })
        .catch((error) => {
          console.log("img",error);
        });
    },

    getUserMedia() {
      var self = this;

      this.cameraError = false;

      const mediaParams = {
        audio: true,
        video: true,
        options: {
          muted: true,
          mirror: true,
        },
      };

      this.session
        .getUserMedia(mediaParams)
        .then((localStream) => {
          self.allowing = false;
          self.session.attachMediaStream("localStream", localStream);
          /*this.audioTrack = null;
          var ats = localStream.getAudioTracks();
          if (ats.length>0) {
            this.audioTrack = ats[0];
            this.audioTrack.enabled = false;
          }*/
          setTimeout(() => {
            self.captureImg(3);
          }, 1000);
        })
        .catch((error) => {
          console.log("session2",error);
          self.cameraError = true;
        });
    },

    async hangup(sessId) {
      this.reloading = true;
      if (this.hangupCalled) {
        return;
      }
      this.hangupCalled = true;
      if (this.session) {
        sessId = this.session.ID;
        this.session.stop({});
      }
      if (!this.showCanvas) {
          this.$emit('ring',false);
          //this.ringing = false;
          const calleesIds = Object.keys(this.contacts);
          try {
            await this.sendPush(calleesIds.map(function(item) { return parseInt(item, 10); }),sessId,null,"");
            if (this.userAnswer) {
              await store.dispatch("saveCall", {QRId:this.QRId,floorId:this.floor,apartmentId:this.apartment,call:{id:this.call,dur:this.call_time - this.time}});
            }
          } catch (err) {
            console.log(err);
          }
      }
      location.replace("/building/" + this.QRId);
      //this.$router.replace({ name: 'virtual', params: { virtual: this.QRId } });
    },

    onAcceptCallListener(session, userId, extension) {
      this.calling = false;
      this.$emit('ring',false);
      //this.ringing = false;
      /*if (this.audioTrack) {
        this.audioTrack.enabled = true;
      }*/
      
      if (this.oponentConnection) {
        session.stopOnlyThis(userId,this.oponentConnection.userID,this.contacts[this.oponentConnection.userID].name);
        var stopthis = [];
        for (var i=0;i<Object.keys(this.contacts).length;i++) {
          if (Object.keys(this.contacts)[i]==userId) {
            stopthis.push(parseInt(Object.keys(this.contacts)[i],10));
            break;
          }
        }
        this.sendPush(stopthis,session.ID,null,this.contacts[this.oponentConnection.userID].name);
        return;
      }
      this.oponentConnection = session.getPeerConnection(userId);

      session.stopAllButConnected(userId,this.contacts[userId].name);

      var stopothers = [];
      for (var h=0;h<Object.keys(this.contacts).length;h++) {
        if (Object.keys(this.contacts)[h]!=userId) {
            stopothers.push(parseInt(Object.keys(this.contacts)[h],10));
        }
      }
      this.sendPush(stopothers,session.ID,null,this.contacts[userId].name);

      store.dispatch("saveCall", {QRId:this.QRId,floorId:this.floor,apartmentId:this.apartment,call:{id:this.call,uid:this.contacts[userId].id}});
    },

    onRejectCallListener(session, userId, extension) {
    },

    onUserNotAnswerListener(session, userId, extension) {
    },

    onStopCallListener(session, userId, extension) {
    },

    onSessionCloseListener(session) {
      this.session = null;
      this.hangup(session.ID);
    },

    onRemoteStreamListener(session, userId, remoteStream) {
      console.log('onRemoteStreamListener');
      session.attachMediaStream("remoteStream", remoteStream);
//      session.attachMediaStream("remoteStreamAudio", remoteStream);
    },

    onDataChannelMessageListener(session, userId, data) {
      if (this.contacts[userId]) {
        if (data.length!=2) {
          return;
        }
        var action = data.charAt(0);
        switch (action) {
          case 'D': // action open door
  Vue.axios.get("https://us-central1-tappbellcloud.cloudfunctions.net/PdoRPEN/" + this.QRId + "/" + data.charAt(1))
  .then((response) => {
  })
  .catch((error) => {
    //console.log('catched error', error);
  });
            break;
          case 'V': // action mute video
            if (parseInt(data.charAt(1))==0) {
              this.showRemote = false;
            }
            else {
              this.showRemote = true;
            }
            break;
        }
      }
    },

    onDataChannelOpenListener(session, userId) {
      session.getPeerConnection(userId).sendData("D" + this.doors);
      console.log('onDataChannelOpenListener');
      this.userAnswer = userId;
      this.time = this.call_time;
      var self = this;
      this.timeinterval = setInterval(() => {
        self.time--;
        if (self.time == 0) {
          self.hangup();
        }
      }, 1000);
    },

    onDataChannelCloseListener(session, userId) {
    },

    sendPush(users,sessionId,imgdata,stopdata) {
      var payload = {
        ios_mutable_content: 1,
        expiration: (Math.floor(Date.now() / 1000) + 5),
        session: sessionId ? sessionId : '',
        building: this.building,
        ios_category: "call",
        ios_sound: "ringtone.wav",
        message: this.building,
        type: 0
      };
      if (imgdata) {
        payload["photo"] = imgdata;
      }
      if (stopdata!=undefined) {
        payload["stop"] = true;
        payload["stop_data"] = stopdata;
      }
      else {
        payload["ios_voip"] = true;
      }

      const pushParameters = {
        notification_type: "push",
        user: { ids: users }, // recipients.
        environment: "production",
        message: ConnectyCube.pushnotifications.base64Encode(JSON.stringify(payload)),
      };

      var pushPromise = ConnectyCube.pushnotifications.events
        .create(pushParameters);
      pushPromise.then((result) => {console.log('PUSH EVENT', result);})
        .catch((error) => {console.log('PUSH EVENT ERROR', error);});

      return pushPromise;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/_mixins.scss";

.hangup {
  position: absolute;
  bottom: 11rem;
  left: 50%;
  transform: translateX(-50%);

  @include sm {
    bottom: 6rem;
  }
  
  @include md {
    bottom: 7rem;
  }

  @include lg-up {
    bottom: 8rem;
  }
}

.localStream {
  width: 100%;
  video {
    width: 100%;
  }

  &.pip {
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 6rem;
    height: 6rem;

    @include sm {
      width: 10rem;
      height: 10rem;
    }
    
    @include md {
      width: 12rem;
      height: 12rem;
    }

    @include lg-up {
      width: 16rem;
      height: 16rem;
    }
  }
}

.remoteStream {

  video {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: center;    
  }
}
.blink {
  animation: blink 1s linear infinite;
}
@keyframes blink{
  0%{opacity: 0;}
  25%{opacity: 0.75;}
  50%{opacity: 1;}
}

.crop{
  object-fit: cover;
  object-position: center;
  width: 6rem;
  height: 6rem;

  @include sm {
    width: 10rem;
    height: 10rem;
  }
  
  @include md {
    width: 12rem;
    height: 12rem;
  }

  @include lg-up {
    width: 16rem;
    height: 16rem;
  }
}
</style>